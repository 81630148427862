import React, { useState, useRef } from 'react'

import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import { ReactComponent as FilterIcon } from 'Assets/Icons/Filter.svg'
import { TICKET_STATUS } from 'Config/constants'
import { useClickOutside } from 'Components/utils/customHooks'
import { TicketStatusIcon } from 'Components/atoms'

import { FilterButton, FilterContainer, MainText } from './styled'

const FilterTicket = ({ className, status, onChange }) => {
  const filterRef = useRef()
  const { t } = useTranslation()

  const [displayFilter, setDisplayFilter] = useState('none')

  const closeFilters = () => setDisplayFilter('none')

  const handleClick = newStatus => {
    onChange(newStatus)
    closeFilters()
  }

  useClickOutside(filterRef, closeFilters)

  const returnContent = () => {
    switch (status) {
      case TICKET_STATUS.new:
        return (
          <>
            <MainText>{t('ticketingStatus.new')}</MainText>
            <TicketStatusIcon style={{ marginLeft: '12px' }} status='new' />
          </>
        )
      case TICKET_STATUS.open:
        return (
          <>
            <MainText>{t('ticketingStatus.open')}</MainText>
            <TicketStatusIcon style={{ marginLeft: '12px' }} status='open' />
          </>
        )
      case TICKET_STATUS.pending:
        return (
          <>
            <MainText>{t('ticketingStatus.pending')}</MainText>
            <TicketStatusIcon style={{ marginLeft: '12px' }} status='pending' />
          </>
        )
      case TICKET_STATUS.solved:
        return (
          <>
            <MainText>{t('ticketingStatus.solved')}</MainText>
            <TicketStatusIcon style={{ marginLeft: '12px' }} status='solved' />
          </>
        )
      case TICKET_STATUS.closed:
        return (
          <>
            <MainText>{t('ticketingStatus.closed')}</MainText>
            <TicketStatusIcon style={{ marginLeft: '12px' }} status='closed' />
          </>
        )
      default:
        return (
          <>
            <MainText>{t('ticketingFilterStatus')}</MainText>
            <FilterIcon />
          </>
        )
    }
  }

  return (
    <div className={className} ref={filterRef}>
      <FilterButton onClick={() => setDisplayFilter(value => (value === 'none' ? 'flex' : 'none'))}>
        {returnContent()}
      </FilterButton>
      <FilterContainer display={displayFilter}>
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('ticketingStatus.new')} <TicketStatusIcon style={{ marginLeft: '12px' }} status='new' />
            </div>
          }
          labelPlacement='start'
          checked={status === TICKET_STATUS.new}
          onClick={() => handleClick(TICKET_STATUS.new)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('ticketingStatus.open')} <TicketStatusIcon style={{ marginLeft: '12px' }} status='open' />
            </div>
          }
          labelPlacement='start'
          checked={status === TICKET_STATUS.open}
          onClick={() => handleClick(TICKET_STATUS.open)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('ticketingStatus.pending')} <TicketStatusIcon style={{ marginLeft: '12px' }} status='pending' />
            </div>
          }
          labelPlacement='start'
          checked={status === TICKET_STATUS.pending}
          onClick={() => handleClick(TICKET_STATUS.pending)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('ticketingStatus.solved')} <TicketStatusIcon style={{ marginLeft: '12px' }} status='solved' />
            </div>
          }
          labelPlacement='start'
          checked={status === TICKET_STATUS.solved}
          onClick={() => handleClick(TICKET_STATUS.solved)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {t('ticketingStatus.closed')} <TicketStatusIcon style={{ marginLeft: '12px' }} status='closed' />
            </div>
          }
          labelPlacement='start'
          checked={status === TICKET_STATUS.closed}
          onClick={() => handleClick(TICKET_STATUS.closed)}
        />
        <FormControlLabel
          value='start'
          control={<Radio color='primary' style={{ padding: '6px 9px' }} />}
          label={t('any')}
          labelPlacement='start'
          checked={status === ''}
          onClick={() => handleClick('')}
        />
      </FilterContainer>
    </div>
  )
}

FilterTicket.defaultProps = {
  className: '',
  status: '',
  onChange: () => {},
}

FilterTicket.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func,
}

export default FilterTicket
