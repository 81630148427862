import map from 'lodash/map'
import { TICKET_STATUS } from 'Config/constants'

export const mapTicketsList = list =>
  map(list, ({ status, ...ticket }) => {
    let parsedStatus = status
    if (status === TICKET_STATUS.hold) {
      parsedStatus = TICKET_STATUS.open
    } else if (status === TICKET_STATUS.closed) {
      parsedStatus = TICKET_STATUS.closed
    }
    return { ...ticket, status: parsedStatus }
  })
