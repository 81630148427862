import React from 'react'

import PropTypes from 'prop-types'
import { TICKET_STATUS, TICKET_STATUS_COLOR } from 'Config/constants'
import { ReactComponent as IconNew } from 'Assets/Icons/TicketingNew.svg'
import { ReactComponent as IconPending } from 'Assets/Icons/TicketingPending.svg'
import { ReactComponent as IconProgress } from 'Assets/Icons/TicketingProgress.svg'
import { ReactComponent as IconSolved, ReactComponent as IconClosed } from 'Assets/Icons/TicketingSolved.svg'

const TicketStatusIcon = ({ status, style, ...rest }) => {
  const statusColor = TICKET_STATUS_COLOR[status]

  if (status === TICKET_STATUS.new) return <IconNew style={{ ...style, fill: statusColor }} {...rest} />
  if (status === TICKET_STATUS.open) return <IconProgress style={{ ...style, fill: statusColor }} {...rest} />
  if (status === TICKET_STATUS.pending) return <IconPending style={{ ...style, fill: statusColor }} {...rest} />
  if (status === TICKET_STATUS.solved) return <IconSolved style={{ ...style, fill: statusColor }} {...rest} />
  if (status === TICKET_STATUS.closed) return <IconClosed style={{ ...style, fill: statusColor }} {...rest} />
  return ''
}

TicketStatusIcon.propTypes = {
  status: PropTypes.string,
  style: PropTypes.any,
}

TicketStatusIcon.defaultProps = {
  status: '',
  style: {},
}

export default TicketStatusIcon
